/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/DataSnippet/DataSnippetSetting/index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-22 13:52:55
 * :last editor: WSR
 * :date last edited: 2024-10-10 14:12:31
 */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Tooltip,
  Checkbox,
  Divider,
  CheckboxOptionType,
  message,
} from 'antd';
import _ from 'lodash';
import IconFont from '@/components/IconFont';
import SortOptions from './SortOptions';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { useModel } from 'umi';
import c from './index.less';
import { PsiBiztype } from '../contants';
import {
  SYSTEM_FIELDS,
  PROCESS_FORM_SYSTEM_FIELDS,
  CUSTOMER_FORM_SYSTEM_FIELDS,
  CUSTOMER_BIZ_TYPES,
} from '../contants';

enum formType {
  // 0:流程表单
  PROCESS_FORM,
  // 1:非流程表单
  NON_PROCESS_FORM,
}

const SUPPORTED_NOW = [
  'input',
  'textarea',
  'number',
  'date',
  'dateRange',
  'select',
  'multiSelect',
  'phone',
  'money',
  'innerContact',
  'department',
  'address',
  'seqNumber',
  'salesStage',
  'relation',
  'cascade',
  'cascadeV2',
  'multiCascade',
];

const SUPPORTED_ID = ['cooperator', 'principal'];

export default function FilterOptionsSetting(props: { [key: string]: any }) {
  const { visible, onCancel, onOk, initValue } = props;
  const [value, setValue] = useState<string[]>([]);
  const [allOptions, setAllOptions] = useState<CheckboxOptionType[]>([]);
  const [commonOptions, setCommonOptions] = useState<CheckboxOptionType[]>([]);
  const model = useModel('useGenerator', (model) => model);
  const { selected } = useStore();
  const { schemaRef, profile } = model;
  const properties = schemaRef.current.properties || {};

  const isShowProcessDesign =
    PsiBiztype.includes(profile?.bizType) ||
    profile.formType === formType.PROCESS_FORM;

  const fieldList = Object.keys(properties)
    .map((key) => {
      return {
        ...properties[key],
        id: key,
      };
    })
    .filter((item) => {
      // 单行输入框、数字输入框、金额、单选项、电话、日期、地址
      return SUPPORTED_NOW.includes(item.widget);
    })
    .filter((item) => {
      // 过滤掉未启用的字段
      return !item.hidden;
    });

  useEffect(() => {
    if (visible) {
      const fieldList = Object.keys(properties)
        .map((key) => {
          return {
            ...properties[key],
            id: key,
          };
        })
        .filter((item) => {
          // 负责人、协同人
          // 单行输入框、数字输入框、金额、单选项、电话、日期、地址
          return (
            SUPPORTED_ID.includes(item.id) ||
            SUPPORTED_NOW.includes(item.widget)
          );
        })
        .filter((item) => {
          // 过滤掉未启用的字段
          return !item.hidden || (item.hidden && item.hideControls);
        });
      const allOptionsTemp = fieldList.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.title,
        };
      });

      if (isShowProcessDesign) {
        setAllOptions([
          ...allOptionsTemp,
          ...SYSTEM_FIELDS,
          ...PROCESS_FORM_SYSTEM_FIELDS,
        ]);
      } else {
        let _data = [...allOptionsTemp, ...SYSTEM_FIELDS];
        if (profile.bizType && CUSTOMER_BIZ_TYPES.includes(profile.bizType)) {
          _data = [..._data, ...CUSTOMER_FORM_SYSTEM_FIELDS];
        }
        setAllOptions(_data);
      }
      const checkValue = (initValue.propsValue || []).map((item) => item.id);
      setValue(checkValue);
    }
  }, [visible, initValue.propsValue, selected]);

  const onChange = (checkedValues: any[]) => {
    console.log('checkedValues ', checkedValues);
    if (checkedValues?.length > 5) {
      message.warn('最多选择5个字段');
      return;
    }
    setCommonOptions(allOptions.filter((v) => checkedValues.includes(v.value)));
    setValue(checkedValues);
  };

  const handleOk = () => {
    onOk(commonOptions);
  };

  useEffect(() => {
    // setCommonOptions(allOptions.filter((v) => value.includes(v.value)));
    setCommonOptions(value?.map(i => {
      const index = allOptions?.findIndex(k => k?.value === i);
      if(index === -1) {
        return null
      } else {
        return allOptions?.[index]
      }
    })?.filter(i => !!i));
  }, [value]);

  const content = (
    <div>
      <div>常用筛选项将展示在列表上方</div>
      <div>您可以对常用筛选项进行移动、隐藏</div>
      <div className={c.operating_instructions}></div>
    </div>
  );

  return (
    <div className={c.filter_options_setting}>
      <Modal
        width={600}
        title="数据摘要"
        visible={visible}
        okText="确定"
        cancelText="取消"
        onOk={handleOk}
        onCancel={onCancel}
        wrapClassName={c['modal-wrap']}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div className={c.filter_options_container}>
          <div className={c.all_options}>
            <div className={c['type-title']}>
              选择展示字段&nbsp;
              <Tooltip title="最多选择5个字段">
                <IconFont
                  style={{ color: '#a5a6a8', fontSize: 14 }}
                  type="icon-shuoming"
                ></IconFont>
              </Tooltip>
            </div>
            <div className={c.all_options_items}>
              <Checkbox.Group
                value={value}
                options={allOptions}
                onChange={onChange}
              />
            </div>
          </div>
          <Divider type="vertical" style={{ width: '1px', height: 'auto' }} />
          <div className={c.common_options}>
            <div>
              <div className={c['type-title']}>
                展示排序（{commonOptions.length}）
              </div>
              {/* <Popover content={content}>
                <IconFont
                  style={{ color: '#a5a6a8' }}
                  type="icon-shuoming"
                ></IconFont>
              </Popover> */}
              <SortOptions
                items={commonOptions}
                updateItems={setCommonOptions}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
